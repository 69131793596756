import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { customMedia } from '../../common/customMedia'
import { Reveal, Animation } from 'react-genie'

import { Image } from '../../common/Image'

export interface Props {
  color: string
  enTitle?: string
  jaTitle: string
}

export interface OwnProps {
  children: ReactNode
}

export const Section = (props: Props & OwnProps) => {
  const { color, enTitle, jaTitle } = props
  const { children } = props

  return (
    <Container color={color}>
      <Reveal delay={500} animation={Animation.FadeIn}>
        <ZoWrapper>
          <Image path="about_zo@2x.png" />
        </ZoWrapper>
      </Reveal>
      <Reveal delay={700} animation={Animation.FadeIn}>
        <EnTitle color={color}>{enTitle}</EnTitle>
      </Reveal>
      <Reveal delay={900} animation={Animation.FadeIn}>
        <JaTitle color={color}>{jaTitle}</JaTitle>
      </Reveal>
      {children}
    </Container>
  )
}

const SomeComponent = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const Container = styled.div<{color: string}>`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  background-color: ${props => props.color === 'white' ? '#fff' : '#0055A4'};
  overflow: hidden;
`

const ZoWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 20px auto 0;
    width: 30px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 36px auto 0;
    width: 47px;
  `}
`

const EnTitle = styled.div<{color: string}>`
  ${customMedia.lessThan("medium")`
    font-size: 26px;
    margin-top: 15px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 36px;
    margin-top: 20px;
  `}

  color: ${props => props.color === 'white' ? '#0055A4' : '#fff'};
  font-family: 'Quicksand', sans-serif;
    letter-spacing: 0.1em;
    text-align: center;
`

const JaTitle = styled.div<{color: string}>`
  ${customMedia.lessThan("medium")`
    font-size: 12px;
    margin-top: 10px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 16px;
    margin-top: 10px;
  `}

  color: ${props => props.color === 'white' ? '#0055A4' : '#fff'};
  font-family: 'Kosugi Maru', sans-serif;
    letter-spacing: 0.36em;
    text-align: center;
`