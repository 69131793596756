import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { customMedia } from '../components/common/customMedia'
import { Carousel } from 'react-responsive-carousel'
import { Reveal, Animation } from 'react-genie'
import Fade from 'react-reveal/Fade'

import { Layout } from '../components/Layout'
import { SEO } from '../components/common/SEO'
import { SpOnly } from '../components/common/SpOnly'
import { PcOnly } from '../components/common/PcOnly'
import { SpBr } from '../components/common/SpBr'
import { PcBr } from '../components/common/PcBr'
import { Image } from '../components/common/Image'
import { MyButton } from '../components/common/MyButton'
import { Section } from '../components/Index/Section'
import { Map } from '../components/common/Map'

const Video = require('../videos/shinbi-do.mp4')

const coverCheckImageSpSrc = require('../images/top_check_sp.jpg')
const coverCheckImagePcSrc = require('../images/top_check_pc.jpg')

const coverImage1SpSrc = require('../images/main1_sp.jpg')
const coverImage2SpSrc = require('../images/main2_sp.jpg')
const coverImage3SpSrc = require('../images/main3_sp.jpg')
const coverImage4SpSrc = require('../images/main4_sp.jpg')
const coverImage5SpSrc = require('../images/main5_sp.jpg')

const coverImage1PcSrc = require('../images/main1_pc.jpg')
const coverImage2PcSrc = require('../images/main2_pc.jpg')
const coverImage3PcSrc = require('../images/main3_pc.jpg')
const coverImage4PcSrc = require('../images/main4_pc.jpg')
const coverImage5PcSrc = require('../images/main5_pc.jpg')

const About = () => (
  <Section color="white" enTitle="ABOUT US" jaTitle="わたしたち">
    <Reveal delay={1000} animation={Animation.FadeIn}>
      <VideoWrapper>
        <video controls>
          <source src={Video} type="video/mp4" />
        </video>
      </VideoWrapper>
    </Reveal>
    <Reveal delay={700} animation={Animation.FadeIn}>
      <AboutMessage>暮らしの信頼を提供する薬局</AboutMessage>
    </Reveal>
    <Reveal delay={900} animation={Animation.FadeIn}>
      <AboutSubMessage>
        軽井沢・中軽井沢・御代田エリアにお住まいの皆様を中心に、
        <PcBr />
        暮らしの安心を提供することを私たちのモットーにしております。 <PcBr />
        お薬のことだけでなく、地域の情報案内などを通じて、
        <PcBr />
        お客様との信頼関係を築くことを一番大切にしております。 <PcBr />
        シンビ堂はマニュアルだけでないお客様とのコミュニケーションを
        <PcBr />
        大切にする地域密着薬局です。
        <PcBr />
      </AboutSubMessage>
    </Reveal>

    <Reveal delay={1100} animation={Animation.FadeIn}>
      <ButtonWrapper>
        <Link to="/about">
          <MyButton color="blue" text="もっと見る" />
        </Link>
      </ButtonWrapper>
    </Reveal>
  </Section>
)

const Store = () => (
  <Section color="blue" enTitle="STORE" jaTitle="店舗案内">
    <StoreCardContainer>
      <Reveal delay={500} animation={Animation.FadeInUp}>
        <Link to="/store#karuizawahonten">
          <StoreCard>
            <StoreImageWrapper>
              <Image path="karuizawahonten.jpg" />
            </StoreImageWrapper>
            <StoreName>シンビ堂薬局軽井沢本店</StoreName>
          </StoreCard>
        </Link>
      </Reveal>

      <Reveal delay={700} animation={Animation.FadeInUp}>
        <Link to="/store#shinbidodrugs">
          <StoreCard>
            <StoreImageWrapper>
              <Image path="shinbidodrugs.jpg" />
            </StoreImageWrapper>
            <StoreName>シンビ堂ドラッグス</StoreName>
          </StoreCard>
        </Link>
      </Reveal>

      <Reveal delay={900} animation={Animation.FadeInUp}>
        <Link to="/store#nakakaruizawa">
          <StoreCard>
            <StoreImageWrapper>
              <Image path="nakakaruizawa.jpg" />
            </StoreImageWrapper>
            <StoreName>シンビ堂中軽井沢薬局</StoreName>
          </StoreCard>
        </Link>
      </Reveal>

      <Reveal delay={1100} animation={Animation.FadeInUp}>
        <Link to="/store#kariyado">
          <StoreCard>
            <StoreImageWrapper>
              <Image path="kariyado.jpg" />
            </StoreImageWrapper>
            <StoreName>シンビ堂借宿薬局</StoreName>
          </StoreCard>
        </Link>
      </Reveal>

      <Reveal delay={1300} animation={Animation.FadeInUp}>
        <Link to="/store#miyota">
          <StoreCard>
            <StoreImageWrapper>
              <Image path="miyota.jpg" />
            </StoreImageWrapper>
            <StoreName>シンビ堂御代田薬局</StoreName>
          </StoreCard>
        </Link>
      </Reveal>
    </StoreCardContainer>

    <Reveal delay={1300} animation={Animation.FadeInUp}>
      <ButtonWrapper>
        <Link to="/store">
          <MyButton color="white" text="店舗一覧へ" />
        </Link>
      </ButtonWrapper>
    </Reveal>
  </Section>
)

const Recruit = () => (
  <>
    <RecruitContent>
      <RecruitImageWrapper>
        {/* <Reveal delay={700} animation={Animation.FadeInUp}> */}
        <Fade>
          <Image path="main1_pc.jpg" />
        </Fade>
        {/* </Reveal> */}
      </RecruitImageWrapper>
      <RecruitDescription>
        {/* <Reveal delay={900} animation={Animation.FadeInUp}> */}
        <Fade>
          <RecruitDescriptionHeader>
            薬剤師・販売スタッフ募集
          </RecruitDescriptionHeader>
        </Fade>
        {/* </Reveal> */}
        {/* <Reveal delay={1100} animation={Animation.FadeInUp}> */}
        <Fade>
          <RecruitMessage>
            個人力を生かせる職場です。
            <PcBr />
            自分の意思を最大限に生かしてみませんか？
          </RecruitMessage>
        </Fade>
        {/* </Reveal> */}
        {/* <Reveal delay={1300} animation={Animation.FadeInUp}> */}
        <Fade>
          <RecruitSubMessage>
            私たちの使命は「暮らしの信頼を提供する」ことです。調剤薬局の現場はマニュアル化され管理されています。シンビ堂はマニュアルだけでないお客様とのコミュニケーションを通して信頼を築く地域密着薬局です。
          </RecruitSubMessage>
        </Fade>
        {/* </Reveal> */}
      </RecruitDescription>
    </RecruitContent>

    <LinkContainer>
      <SpOnly>
        <Reveal delay={500} animation={Animation.FadeInRight}>
          <RecruitDetailBoxWrapper>
            <RecruitDetailBox>
              <RecruitDetailContent>
                <RecruitDetailImageWrapper>
                  <Image path="top_recruit_details@2x.png" />
                </RecruitDetailImageWrapper>
                <RecruitText>
                  新卒採用
                  <br />
                  転職・中途採用
                </RecruitText>
              </RecruitDetailContent>
              <RecruitButtonWrapper>
                <Link to="/recruit">
                  <MyButton text="詳しく見る" color="pink" />
                </Link>
              </RecruitButtonWrapper>
            </RecruitDetailBox>
          </RecruitDetailBoxWrapper>
        </Reveal>
      </SpOnly>
      <PcOnly>
        <Reveal delay={500} animation={Animation.FadeInLeft}>
          <RecruitDetailBoxWrapper>
            <RecruitDetailBox>
              <RecruitDetailContent>
                <RecruitDetailImageWrapper>
                  <Image path="top_recruit_details@2x.png" />
                </RecruitDetailImageWrapper>
                <RecruitText>
                  新卒採用
                  <br />
                  転職・中途採用
                </RecruitText>
              </RecruitDetailContent>
              <RecruitButtonWrapper>
                <Link to="/recruit">
                  <MyButton text="詳しく見る" color="pink" />
                </Link>
              </RecruitButtonWrapper>
            </RecruitDetailBox>
          </RecruitDetailBoxWrapper>
        </Reveal>
      </PcOnly>

      <SpOnly>
        <Reveal delay={1000} animation={Animation.FadeInLeft}>
          <RecruitApplyBox>
            <RecruitApplyContent>
              <RecruitApplyImageWrapper>
                <Image path="top_recruit_apply@2x.png" />
              </RecruitApplyImageWrapper>
              <RecruitText>
                募集要項
                <br />
                ご応募はこちら
              </RecruitText>
            </RecruitApplyContent>
            <RecruitButtonWrapper>
              <Link to="/recruit/entry">
                <MyButton text="応募する" color="pink" />
              </Link>
            </RecruitButtonWrapper>
          </RecruitApplyBox>
        </Reveal>
      </SpOnly>
      <PcOnly>
        <Reveal delay={1000} animation={Animation.FadeInRight}>
          <RecruitApplyBox>
            <RecruitApplyContent>
              <RecruitApplyImageWrapper>
                <Image path="top_recruit_apply@2x.png" />
              </RecruitApplyImageWrapper>
              <RecruitText>
                募集要項
                <br />
                ご応募はこちら
              </RecruitText>
            </RecruitApplyContent>
            <RecruitButtonWrapper>
              <Link to="/recruit/entry">
                <MyButton text="応募する" color="pink" />
              </Link>
            </RecruitButtonWrapper>
          </RecruitApplyBox>
        </Reveal>
      </PcOnly>
    </LinkContainer>
  </>
)

const IndexPage = () => {
  const [showFirstCover, setShowFirstCover] = useState(true)
  useEffect(() => {
    setTimeout(() => setShowFirstCover(false), 5000)
  }, [])

  return (
    <Layout>
      <SEO
        title="軽井沢エリアを地域密着で信頼を提供する薬局 | シンビ堂"
        description="シンビ堂は軽井沢を中心に5店舗展開する薬局です。(軽井沢・中軽井沢・プリンスショッピングプラザ・御代田・借宿)地域密着で地域の皆様の暮らしに信頼を提供しています。薬のこと地域の暮らしのことなど、お気軽にご相談ください。"
        keywords={[
          'シンビ堂',
          '薬局',
          '軽井沢',
          '御代田',
          '中軽井沢',
          'プリンスショッピングプラザ'
        ]}
      />
      <h1>軽井沢 薬局 御代田 中軽井沢 プリンスショッピングプラザ | シンビ堂</h1>

      <SpOnly>
        <Reveal delay={500} animation={Animation.FadeIn}>
          <FirstCoverContainer showFirstCover={showFirstCover}>
            <FirstCoverMessage>
              <Reveal delay={1000} animation={Animation.FadeIn}>
                暮
              </Reveal>
              <Reveal delay={1100} animation={Animation.FadeIn}>
                ら
              </Reveal>
              <Reveal delay={1200} animation={Animation.FadeIn}>
                し
              </Reveal>
              <Reveal delay={1300} animation={Animation.FadeIn}>
                の
              </Reveal>
              <Reveal delay={1400} animation={Animation.FadeIn}>
                信
              </Reveal>
              <Reveal delay={1500} animation={Animation.FadeIn}>
                頼
              </Reveal>
              <Reveal delay={1600} animation={Animation.FadeIn}>
                を<br />
              </Reveal>
              <Reveal delay={1700} animation={Animation.FadeIn}>
                提
              </Reveal>
              <Reveal delay={1800} animation={Animation.FadeIn}>
                供
              </Reveal>
              <Reveal delay={1900} animation={Animation.FadeIn}>
                す
              </Reveal>
              <Reveal delay={2000} animation={Animation.FadeIn}>
                る
              </Reveal>
              <Reveal delay={2100} animation={Animation.FadeIn}>
                薬
              </Reveal>
              <Reveal delay={2200} animation={Animation.FadeIn}>
                局
              </Reveal>
            </FirstCoverMessage>
            <CoverImageWrapper>
              <img src={coverCheckImageSpSrc} alt={coverCheckImageSpSrc} />
            </CoverImageWrapper>
          </FirstCoverContainer>
        </Reveal>

        <CarouselWrapper showFirstCover={showFirstCover}>
          <Carousel
            showIndicators={false}
            showStatus={false}
            infiniteLoop={true}
            showThumbs={false}
            autoPlay={true}
            interval={5000}
            showArrows={false}
          >
            <CoverImageWrapper>
              <img src={coverImage1SpSrc} alt={coverImage1SpSrc} />
            </CoverImageWrapper>
            <CoverImageWrapper>
              <img src={coverImage2SpSrc} alt={coverImage2SpSrc} />
            </CoverImageWrapper>
            <CoverImageWrapper>
              <img src={coverImage3SpSrc} alt={coverImage3SpSrc} />
            </CoverImageWrapper>
            <CoverImageWrapper>
              <img src={coverImage4SpSrc} alt={coverImage4SpSrc} />
            </CoverImageWrapper>
            <CoverImageWrapper>
              <img src={coverImage5SpSrc} alt={coverImage5SpSrc} />
            </CoverImageWrapper>
          </Carousel>
        </CarouselWrapper>
      </SpOnly>

      <PcOnly>
        <Reveal delay={500} animation={Animation.FadeIn}>
          <FirstCoverContainer showFirstCover={showFirstCover}>
            <FirstCoverMessage>
              <Reveal delay={1000} animation={Animation.FadeIn}>
                暮
              </Reveal>
              <Reveal delay={1100} animation={Animation.FadeIn}>
                ら
              </Reveal>
              <Reveal delay={1200} animation={Animation.FadeIn}>
                し
              </Reveal>
              <Reveal delay={1300} animation={Animation.FadeIn}>
                の
              </Reveal>
              <Reveal delay={1400} animation={Animation.FadeIn}>
                信
              </Reveal>
              <Reveal delay={1500} animation={Animation.FadeIn}>
                頼
              </Reveal>
              <Reveal delay={1600} animation={Animation.FadeIn}>
                を
              </Reveal>
              <Reveal delay={1700} animation={Animation.FadeIn}>
                提
              </Reveal>
              <Reveal delay={1800} animation={Animation.FadeIn}>
                供
              </Reveal>
              <Reveal delay={1900} animation={Animation.FadeIn}>
                す
              </Reveal>
              <Reveal delay={2000} animation={Animation.FadeIn}>
                る
              </Reveal>
              <Reveal delay={2100} animation={Animation.FadeIn}>
                薬
              </Reveal>
              <Reveal delay={2200} animation={Animation.FadeIn}>
                局
              </Reveal>
            </FirstCoverMessage>
            <CoverImageWrapper>
              <img src={coverCheckImagePcSrc} alt={coverCheckImagePcSrc} />
            </CoverImageWrapper>
          </FirstCoverContainer>
        </Reveal>

        <CarouselWrapper showFirstCover={showFirstCover}>
          <Carousel
            showIndicators={false}
            showStatus={false}
            infiniteLoop={true}
            showThumbs={false}
            autoPlay={true}
            interval={5000}
            showArrows={false}
          >
            <CoverImageWrapper>
              <img src={coverImage1PcSrc} alt={coverImage1PcSrc} />
            </CoverImageWrapper>
            <CoverImageWrapper>
              <img src={coverImage2PcSrc} alt={coverImage2PcSrc} />
            </CoverImageWrapper>
            <CoverImageWrapper>
              <img src={coverImage3PcSrc} alt={coverImage3PcSrc} />
            </CoverImageWrapper>
            <CoverImageWrapper>
              <img src={coverImage4PcSrc} alt={coverImage4PcSrc} />
            </CoverImageWrapper>
            <CoverImageWrapper>
              <img src={coverImage5PcSrc} alt={coverImage5PcSrc} />
            </CoverImageWrapper>
          </Carousel>
        </CarouselWrapper>
      </PcOnly>

      <SpOnly>
        <Store />
        <About />
      </SpOnly>

      <PcOnly>
        <About />
        <Store />
      </PcOnly>

      <MapWrapper>
        <Map />
      </MapWrapper>

      <PdfLinksWrapper>
        <Reveal delay={500} animation={Animation.FadeIn}>
          <a href="/prescription.pdf" target="_blank" rel="noreferrer">
            <PrescriptionWrapper>
              <PrescriptionImageWrapper>
                <Image path="prescription_icon.png" />
              </PrescriptionImageWrapper>
              <PrescriptionText>
                当薬局は電子処方箋
                <br />
                対応施設です
              </PrescriptionText>
            </PrescriptionWrapper>
          </a>
        </Reveal>

        <Reveal delay={1000} animation={Animation.FadeIn}>
          <a href="/mynocard.pdf" target="_blank" rel="noreferrer">
            <MyNoCardWrapper>
              <MyNoCardImageWrapper>
                <Image path="mynocard_icon.png" />
              </MyNoCardImageWrapper>
              <MyNoCardText>
                当薬局はマイナ保険証
                <br />
                対応施設です
              </MyNoCardText>
            </MyNoCardWrapper>
          </a>
        </Reveal>
      </PdfLinksWrapper>

      <PcOnly>
        <Section color="white" enTitle="RECRUIT" jaTitle="採用情報">
          <Recruit />
        </Section>
      </PcOnly>

      <SpOnly>
        <Section color="blue" enTitle="RECRUIT" jaTitle="採用情報">
          <Recruit />
        </Section>
      </SpOnly>
    </Layout>
  )
}

const FirstCoverContainer = styled.div<{ showFirstCover: boolean }>`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
  `}

  position: absolute;
  z-index: 99;

  transition: 1s;
  opacity: ${props => (props.showFirstCover ? 1 : 0)};

  width: 100%;
`

const FirstCoverMessage = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 2.4rem;
    width: 100%;
    line-height: 40px;
    text-align: center;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 3.6rem;
  `}

  color: #fff;
  font-family: 'Kosugi Maru', sans-serif;
  letter-spacing: 0.32em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);

  div {
    display: inline;
  }
`

const CarouselWrapper = styled.div<{ showFirstCover: boolean }>`
  transition: 1s;
  opacity: ${props => (props.showFirstCover ? 0 : 1)};
`

const CoverImageWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    img {
      height: 369px;
      object-fit: cover;
      overflow: hidden;
      width: 100%;
    }
  `}
  ${customMedia.greaterThan('medium')`
    img {
      height: 679px;
      object-fit: cover;
      overflow: hidden;
      width: 100%;
    }
  `}
`

const SomeComponent = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
  `}
`

const VideoWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin-top: 23px;

    video {
      width: 100%;
    }
  `}
  ${customMedia.between('medium', 'large')`
    margin-top: 38px;

    video {
      width: 768px;
    }
  `}
  ${customMedia.greaterThan('large')`
    margin-top: 38px;

    video {
      width: 900px;
    }
  `}

  video {
    display: block;
    margin: 0 auto;
  }
`

const AboutMessage = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.6rem;
    letter-spacing: 0.12em;
    margin-top: 30px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 2.8rem;
    letter-spacing: 0.28em;
    margin-top: 60px;
  `}

  color: #0055A4;
  font-weight: bold;
  text-align: center;
`

const AboutSubMessage = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.4rem;
    letter-spacing: 0.2em;
    padding: 0 20px;
    margin-top: 20px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 1.6rem;
    letter-spacing: 0.14em;
    line-height: 28px;
    margin-top: 30px;
    text-align: center;
  `}

  color: #0055A4;
`

const ButtonWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 30px 0;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 45px auto 60px;
  `}
`

const StoreCardContainer = styled.div`
  ${customMedia.lessThan('medium')`
    margin-top: 20px;
    padding: 0 20px;
  `}
  ${customMedia.greaterThan('medium')`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 900px;
  `}
`

const StoreCard = styled.div`
  ${customMedia.lessThan('medium')`
    display: -webkit-flex;
    display: flex;
    margin-top: 10px;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 40px 40px 0 0;
    width: 100%;

    :nth-child(3n) {
      margin-right: 0;
    }
  `}
`

const StoreImageWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    width: 110px;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 0 auto;
    width: 240px;
  `}

  img {
    border-radius: 10px;
  }
`

const StoreName = styled.div`
  ${customMedia.lessThan('medium')`
    display: -webkit-flex;
    display: flex;
    font-size: 1.4rem;
    -webkit-align-items: center;
    align-items: center;
    letter-spacing: 0.06em;
    margin-left: 15px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 1.6rem;
    letter-spacing: 0.08em;
    margin: 15px auto 0;
    text-align: center;
    width: 240px;
  `}

  color: #fff;
  font-weight: bold;
`

const MapWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    display: none;
  `}
  ${customMedia.greaterThan('medium')`
  `}
`

const RecruitContent = styled.div`
  ${customMedia.lessThan('medium')`
    margin-top: 18px;
  `}
  ${customMedia.greaterThan('medium')`
    display: -webkit-flex;
    display: flex;
    margin-top: 40px;
  `}
`

const RecruitImageWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    height: 180px;

    img {
      height: 180px !important;
      object-fit: cover;
      overflow: hidden;
      width: 100%;
    }
  `}
  ${customMedia.greaterThan('medium')`
    width: 50%;
    img {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  `}
`

const RecruitDescription = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
    width: 50%;
    margin-left: 45px;
  `}
`

const RecruitDescriptionHeader = styled.div`
  ${customMedia.lessThan('medium')`
    background-color: #fff;
    border-radius: 14px;
    color: #0055A4;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 16px auto 0;
    text-align: center;
    line-height: 28px;
    height: 28px;
    width: 192px;
  `}
  ${customMedia.greaterThan('medium')`
    background-color: #0055A4;
    border-radius: 14px;
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 55px;
    text-align: center;
    line-height: 28px;
    height: 28px;
    width: 220px;
  `}

  letter-spacing: 0.12em;
`

const RecruitMessage = styled.div`
  ${customMedia.lessThan('medium')`
    color: #fff;
    font-size: 1.4rem;
    letter-spacing: 0.02em;
    line-height: 25px;
    margin-top: 18px;
    padding: 0 20px;
  `}
  ${customMedia.greaterThan('medium')`
    color: #0055A4;
    font-size: 1.8rem;
    letter-spacing: 0.14em;
    line-height: 30px;
    margin-top: 18px;
  `}

  font-weight: bold;
`

const RecruitSubMessage = styled.div`
  ${customMedia.lessThan('medium')`
    color: #fff;
    font-size: 1.4rem;
    line-height: 25px;
    padding: 0 20px;
  `}
  ${customMedia.greaterThan('medium')`
    color: #0055A4;
    font-size: 1.6rem;
    letter-spacing: 0.14em;
    line-height: 30px;
    width: 455px;
    margin-top: 16px;
  `}
`

const LinkContainer = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    margin: 60px 0;
  `}
`

const RecruitDetailBoxWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    :after {
      content: '';
      clear: both;
      display: block;
    }
  `}
`

const RecruitDetailBox = styled.div`
  ${customMedia.lessThan('medium')`
    background-color: #fff;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    float: right;
    height: 160px;
    margin-top: 30px;
    width: 300px;
  `}
  ${customMedia.greaterThan('medium')`
    border: 2px #0055A4 solid;
    border-radius: 25px;
    height: 200px;
    margin-right: 20px;
    position: relative;
    width: 430px;
  `}
`

const RecruitDetailContent = styled.div`
  ${customMedia.lessThan('medium')`
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    margin-top: 20px;
  `}
  ${customMedia.greaterThan('medium')`
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    margin-top: 22px;
  `}
`

const RecruitDetailImageWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    width: 65px;
  `}
  ${customMedia.greaterThan('medium')`
    width: 80px;
  `}
`

const RecruitApplyBox = styled.div`
  ${customMedia.lessThan('medium')`
    background-color: #fff;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 160px;
    margin: 20px 0 30px;
    width: 300px;
    overflow: hidden;
  `}
  ${customMedia.greaterThan('medium')`
    border: 2px #0055A4 solid;
    border-radius: 25px;
    height: 200px;
    margin-left: 20px;
    position: relative;
    width: 430px;
  `}
`

const RecruitApplyContent = styled.div`
  ${customMedia.lessThan('medium')`
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    margin-top: 20px;
  `}
  ${customMedia.greaterThan('medium')`
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    margin-top: 30px;
  `}
`

const RecruitApplyImageWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    width: 82px;
  `}
  ${customMedia.greaterThan('medium')`
    width: 100px;
  `}
`

const RecruitText = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.8rem;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 2.2rem;
  `}

  color: #0055A4;
  font-weight: bold;
  letter-spacing: 0.16em;
  margin-left: 20px;
`

const RecruitButtonWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin-top: 15px;
  `}
  ${customMedia.greaterThan('medium')`
    top: 130px;
    position: absolute;
    width: 100%;
  `}
`

const PdfLinksWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin-bottom: 36px;
  `}
  ${customMedia.greaterThan('medium')`
    background-color: #ECEFF3;
    display: flex;
    justify-content: center;
    padding: 40px 0;
  `}
`

const PrescriptionWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    border-radius: 20px;
    margin: 20px;
    height: 100px;
  `}
  ${customMedia.greaterThan('medium')`
    border-radius: 25px;
    margin: 0 18px;
    height: 120px;
    width: 400px;
  `}

  background-color: #fff;
  border: 2px #0055a4 solid;
  display: flex;
  justify-content: center;
  align-items: center;
`

const MyNoCardWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    border-radius: 20px;
    margin: 20px;
    height: 100px;
  `}
  ${customMedia.greaterThan('medium')`
    border-radius: 25px;
    margin: 0 18px;
    height: 120px;
    width: 400px;
  `}
  
  background-color: #fff;
  border: 2px #0055a4 solid;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PrescriptionImageWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin-right: 18px;
    width: 50px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-right: 18px;
    width: 60px;
  `}
`

const MyNoCardImageWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin-right: 18px;
    width: 82px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-right: 18px;
    width: 80px;
  `}
`

const PrescriptionText = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.8rem;
    letter-spacing: 0.02em;
    line-height: 28px;
`}
  ${customMedia.greaterThan('medium')`
    font-size: 2.2rem;
    letter-spacing: 0.14em;
    line-height: 35px;
`}

  color: #0055A4;
  font-weight: bold;
`

const MyNoCardText = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.8rem;
    letter-spacing: 0.02em;
    line-height: 28px;
`}
  ${customMedia.greaterThan('medium')`
    font-size: 2.2rem;
    letter-spacing: 0.14em;
    line-height: 35px;
  `}

  color: #0055A4;
  font-weight: bold;
`

export default IndexPage
