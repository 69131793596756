import React from 'react'
import styled from 'styled-components'
import { customMedia } from '../customMedia'

export interface Props {
  color: string
  text: string
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
}

export const MyButton = (props: Props) => {
  const { color, text, type, disabled} = props

  return (
    <>
      <ButtonWrapper>
        <Button color={color} type={type} disabled={disabled}>{text}</Button>
      </ButtonWrapper>
    </>
  )
}

const ButtonWrapper = styled.div`
  text-align: center;
`

const Button = styled.button<{color: string}>`
  ${customMedia.lessThan("medium")`
    border-radius: 20px;
    font-size: 1.4rem;
    height: 36px;
    letter-spacing: 0.28em;
    margin: 0 auto;
    width: 180px;
  `}
  ${customMedia.greaterThan("medium")`
    border-radius: 20px;
    font-size: 1.6rem;
    height: 42px;
    letter-spacing: 0.28em;
    width: 240px;
  `}

  background-color: ${props => props.color === 'blue' ? '#0055A4' 
    : props.color === 'pink' ? '#C92F68' 
    : '#fff'};
  border: none;
  cursor: pointer;
  color: ${props => props.color === 'white' ? '#0055A4' 
    : props.color === 'white2' ? '#C92F68'
    : '#fff'};
  font-weight: bold;
  text-align: center;
  
  :focus {
    outline:0;
  }

  :hover {
    color: ${props => props.color === 'white2' && '#fff'};
    background-color: ${props => props.color === 'blue' ? '#C92F68'
      : props.color === 'pink' ? '#0055A4'
      : props.color === 'white' ? '#C92F68'
      : props.color === 'white2' ? '#0055A4' 
      : '#fff'};
    ${props => props.color === 'white' && 'color: #fff;'}
    -webkit-transition: all .3s;
    transition: all .3s;
  }
`

